<template>
  <b-row>
    <b-col
      cols="12"
      class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <template>
        <b-card class="mb-3">
          <b-col
            cols="12"
            class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
          >
            <h2>Daftar Waiting List Pendonor - HB</h2>
            <b-button
              class="float-right"
              variant="primary"
              size="sm"
              @click="refreshData"
            >
              <b-icon
                icon="arrow-clockwise"
                class="mr-1"
              />
              Refresh
            </b-button>

            <span>Pilih Lokasi MU : </span>
            <v-select
                v-model="selectedMU"
                placeholder="Pilih Lokasi Mobil Unit"
                :options="listMU.map((v) => ({
                  label: v.name,
                  text: v.name,
                  value: v.id
                }))"
                label="text"
                class="w-100"
                :reduce="(option) => option.value"
                @input="handleInputMU"
            />

          </b-col>
          <TableWaitingListHB
            :is-busy="isBusy"
            :pendonor-data="table.itemsWaiting"
          />
        </b-card>
      </template>
      <template>
        <b-card>
          <b-col
            cols="12"
            class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
          >
            <h2>Daftar List Pendonor - HB</h2>
            <div>
              <span>Semua ( {{ table.items.length }} )</span>
              <div class="row no-gutters w-full float-right">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-black-50"
                      class="border-right-0 pr-0 pl-2"
                      @click="getAllHB"
                    >
                      <i class="ri-search-line remix-icon text-primary" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="table.search"
                    type="search"
                    placeholder="Search"
                    class="border-left-0"
                    @input="debouncedSearch"
                  />
                </b-input-group>
              </div>
            </div>
          </b-col>
          <TableListHB
            :is-busy="isBusy"
            :pendonor-data="table.items"
          />
          <PaginateHB
            :current-page.sync="table.currentPage"
            :per-page="table.perPage"
            :total-rows="table.total"
            @page-changed="onPageChange"
          />
        </b-card>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BCard,
  BIcon,
} from 'bootstrap-vue'
import PaginateHB from '../../components/common/HB/PaginateHB.vue'
import TableListHB from '../../components/common/HB/TableListHB.vue'
import TableWaitingListHB from '../../components/common/HB/TableWaitingListHB.vue'
import tableDataMixins from '../../../../../../mixins/tableDataMixins'

export default {
  name: 'AktivasiHB',
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    PaginateHB,
    TableListHB,
    TableWaitingListHB,
    BCard,
    BIcon,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      items: [],
      itemsWaiting: [],
      searchTitle: '',
      debounceTimer: null,
    }
  },
  computed: {
    activasiHBs() {
      return this.$store.state.manageActivasiHB.activasiHBs
    },
    totalActivationHB() {
      return this.$store.state.manageActivasiHB.totalPages
    },
    isBusy() {
      return this.$store.state.manageActivasiHB.isBusy
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchActivasiHB() {
      this.table.loading = this.isBusy
      await this.$store.dispatch('manageActivasiHB/getActivasiHB', {
        page: this.table.currentPage,
        per_page: this.table.perPage,
        search: this.table.search,
      })
      this.table.items = this.activasiHBs
      this.table.total = this.totalActivationHB
    },
    async fetchWaitingHB() {
      this.table.loading = this.isBusy
      await this.$store.dispatch('manageActivasiHB/getWaitingHB', {
        per_page: this.table.perPage,
      })
      this.table.itemsWaiting = this.activasiHBs
      this.table.total = this.totalActivationHB
    },
    async fetchData() {
      await this.fetchActivasiHB()
      await this.fetchWaitingHB()
    },
    debouncedSearch() {
      clearTimeout(this.table.debounceTimer)
      this.table.debounceTimer = setTimeout(() => {
        this.onChangeSearchTable()
      }, 500)
    },
    onChangeSearchTable() {
      if (this.table.search.trim() !== '') {
        this.getAllHB()
      } else {
        this.getAllHB()
      }
    },
    getAllHB() {
      console.log('Fetching HBs with search term:', this.table.search)
      this.fetchActivasiHB()
    },
    onPageChange(newPage) {
      this.table.currentPage = newPage
      this.fetchData()
    },
    refreshData() {
      this.fetchData()
    },
  },
}
</script>
